"use client"

import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// components
import ChatIcon from "./icons/Chat"

// hooks
import useWindowSize from "../hooks/useWindowSize"
import { useLocale } from "../hooks/useLocale"

// Style
import mq from "../styles/breakpoints"
import colors from "../styles/colors"
import { fontWeights } from "../styles/textStyles"

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 1.56rem;
  right: 1rem;
  z-index: 1000;

  ${mq.from.XSM`
    bottom: 1.56rem;
    right: 1.56rem;
  `}
`

const Button = styled.button<{ $isHovered: boolean; $isDutch: boolean }>`
  background-color: ${colors.purpleLight};
  border: none;
  border-radius: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  width: ${(props) =>
    props.$isHovered ? (props.$isDutch ? "220px" : "160px") : "56px"};
  height: 56px;
  min-width: 56px;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  box-shadow: 0px 8px 20px 0px #362a6640, 0px 0px 4px 1px #0000001f;
`

const ButtonText = styled.span<{ $isHovered: boolean }>`
  color: ${colors.white};
  font-weight: ${fontWeights.medium};
  font-size: 18px;
  line-height: 20px;
  margin-left: 16px;
  margin-right: 16px;
  opacity: ${(props) => (props.$isHovered ? 1 : 0)};
  transform: ${(props) =>
    props.$isHovered ? "translateX(0)" : "translateX(-8px)"};
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition-delay: ${(props) => (props.$isHovered ? "0.1s" : "0s")};
  white-space: nowrap;
  pointer-events: none;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

export default function FloatingContactButton() {
  const [isHovered, setIsHovered] = useState(false)
  const { getLocalizedLink, isDutch } = useLocale()
  const { width } = useWindowSize()
  const isMobile = width <= 615

  // Add timeout ref to clean up
  const timeoutRef = React.useRef<NodeJS.Timeout>()

  const handleMouseEnter = () => {
    if (!isMobile) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      setIsHovered(true)
    }
  }

  const handleMouseLeave = () => {
    if (!isMobile) {
      timeoutRef.current = setTimeout(() => {
        setIsHovered(false)
      }, 100) // Small delay before closing
    }
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  if (
    typeof window !== "undefined" &&
    window.location.pathname.includes("contact")
  ) {
    return null
  }

  return (
    <ButtonContainer>
      <StyledLink to={getLocalizedLink("/contact")}>
        <Button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          $isHovered={isHovered && !isMobile}
          $isDutch={isDutch}
        >
          <IconWrapper>
            <ChatIcon color="white" width={24} height={24} />
          </IconWrapper>
          <ButtonText $isHovered={isHovered && !isMobile}>
            {isDutch ? "Neem contact op" : "Contact us"}
          </ButtonText>
        </Button>
      </StyledLink>
    </ButtonContainer>
  )
}
