import React, { useState, useEffect } from "react"
import localForage from "localforage"

// Styling
import CSSReset from "../styles/reset"
import GlobalStyle from "../styles/"

// Components
import ContextWrapper from "../context/ContextWrapper"
import CookiesNotification from "../components/CookiesNotification"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import LocaleProvider from "../context/LocaleContext"
import FloatingContactButton from "../components/FloatingContactButton"

interface IPageContext {
  navVariant?: "light"
  locale: "nl-nl" | "en-nl"
  name: string
}

interface IProps {
  pageContext: IPageContext
  location: { pathname: string }
}

const Layout: React.FC<IProps> = ({ children, pageContext, location }) => {
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(true)

  useEffect(() => {
    localForage.getItem("cookies", (err, value) => {
      if (err === null && value === "accepted") {
        setCookiesAccepted(true)
      } else {
        setCookiesAccepted(false)
      }
    })
  }, [])

  const handleAcceptCookies = () => {
    localForage.setItem("cookies", "accepted")
    setCookiesAccepted(true)
  }
  return (
    <LocaleProvider locale={pageContext.locale}>
      <CSSReset />
      <GlobalStyle />
      {!cookiesAccepted && (
        <CookiesNotification handleClick={handleAcceptCookies} />
      )}
      <ContextWrapper>
        <Navigation
          locationPathname={location.pathname}
          isLight={pageContext.navVariant === "light"}
        />
        <main>{children}</main>
        <Footer locationPathname={location.pathname} />
      </ContextWrapper>
      <FloatingContactButton />
    </LocaleProvider>
  )
}

export default Layout
