import React from "react"

// Types
import { IIconProps } from "./Icon"

const ChatIcon: React.FCS<IIconProps> = ({
  className,
  width,
  height,
  color,
}) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.94511 18.8415C13.5431 19.2079 15.9739 15.6046 15.9739 11.4123C15.9739 7.21988 12.398 3.82129 7.9869 3.82129C3.57579 3.82129 -0.00012207 7.21988 -0.00012207 11.4123C-0.00012207 13.5983 0.972161 15.5686 2.52815 16.9537C3.52568 17.7128 2.52815 20.5214 2.52815 20.5214C2.52815 20.5214 5.00233 20.3597 6.94511 18.8415Z"
      fill={color}
    />
    <path
      d="M6.9618 18.5411L6.84892 18.5348L6.75984 18.6044C5.83123 19.3301 4.76873 19.736 3.9304 19.9601C3.54095 20.0642 3.20298 20.1282 2.95396 20.1667C2.95528 20.1621 2.95661 20.1574 2.95795 20.1527C3.03794 19.8703 3.1347 19.483 3.19951 19.0687C3.26387 18.6573 3.29931 18.2036 3.24751 17.7937C3.19688 17.393 3.05591 16.9831 2.72032 16.7219C1.2283 15.39 0.300781 13.5025 0.300781 11.4123C0.300781 7.4003 3.72737 4.12219 7.9869 4.12219C12.2464 4.12219 15.673 7.4003 15.673 11.4123C15.673 13.4534 15.0818 15.3135 13.7251 16.6196C12.3704 17.9238 10.2053 18.7212 6.9618 18.5411Z"
      stroke="white"
      stroke-width="0.601807"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.2657 18.0773C15.0779 18.2516 15.9122 18.3431 16.7396 18.3431C18.7707 19.8477 21.3573 20.371 21.3573 20.371C21.3573 20.371 20.3145 17.5876 21.3573 16.8354C22.984 15.4627 24.0005 13.5102 24.0005 11.3439C24.0005 7.18927 20.2621 3.82129 15.6505 3.82129C14.8395 3.82129 14.0556 3.92544 13.3142 4.11969C15.721 5.74343 17.2808 8.34626 17.2808 11.2803C17.2808 13.9675 16.3914 16.4485 14.2657 18.0773Z"
      fill={color}
    />
  </svg>
)

export default ChatIcon
