import React from "react"
import styled from "styled-components"

// Style
import mq from "../styles/breakpoints"

interface IProps {
  id?: string
}

const ContentWrapper: React.FCS<IProps> = ({ id, children, className }) => (
  <Container id={id} className={className}>
    {children}
  </Container>
)

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;

  ${mq.from.S`
    padding: 0 48px;
  `}

  ${mq.from.L`
    padding: 0 88px;
  `}

  ${mq.from.XL`
    max-width: 1440px;
  `}
`

export default ContentWrapper
